import { Breadcrumbs, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { cx } from 'class-variance-authority'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useMatches } from 'react-router-dom'
import styles from './app-breadcrumbs.module.css'

type RouteHandle = {
	crumb: (match: any) => ReactNode
}

type Crumb = {
	match: {
		id: string
		handle: RouteHandle
	}
	breadcrumb: ReactNode
}

export const AppBreadcrumbs = () => {
	const matches = useMatches()
	const [visibleCrumbs, setVisibleCrumbs] = useState<Crumb[]>([])
	const [collapsedCrumbs, setCollapsedCrumbs] = useState<Crumb[]>([])
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const crumbs: Crumb[] = useMemo(() => {
		const crumbs = matches
			.filter((match) => Boolean((match as any).handle?.crumb))
			.map((match) => ({
				match: match as any,
				breadcrumb: (match as any).handle?.crumb(match) as ReactNode,
			}))

		crumbs.shift()

		return crumbs
	}, [matches])

	// Responsive handling
	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth

			if (crumbs.length <= 2) {
				setVisibleCrumbs(crumbs)
				setCollapsedCrumbs([])
			} else if (width < 600) {
				// Mobile: Show first and last
				setVisibleCrumbs([crumbs[0], crumbs[crumbs.length - 1]])
				setCollapsedCrumbs(crumbs.slice(1, -1))
			} else {
				// Tablet: Show first, last two
				setVisibleCrumbs([crumbs[0], ...crumbs.slice(-2)])
				setCollapsedCrumbs(crumbs.slice(1, -2))
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize', handleResize)
	}, [crumbs])

	if (!visibleCrumbs.length) return null

	return (
		<>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'collapsed-breadcrumb-button',
				}}
			>
				{collapsedCrumbs.map((crumb) => (
					<MenuItem key={crumb.match.id} onClick={handleClose} className={styles.menuItem}>
						{crumb.breadcrumb}
					</MenuItem>
				))}
			</Menu>
			<Breadcrumbs separator={<NavigateNextIcon fontSize='small' style={{ color: 'white' }} />} aria-label='breadcrumb'>
				{visibleCrumbs.map((crumb, index) => {
					const isFirst = index === 0
					const isLast = index === visibleCrumbs.length - 1
					const showMenu = isFirst && collapsedCrumbs.length > 0

					return (
						<div key={crumb.match.id} style={{ display: 'flex', alignItems: 'center' }}>
							<Typography component='h4' className={cx(styles['text'], isLast && styles['last'])}>
								{crumb.breadcrumb}
							</Typography>
							{showMenu && (
								<IconButton
									size='small'
									aria-label='show more breadcrumbs'
									id='collapsed-breadcrumb-button'
									aria-controls={open ? 'collapsed-breadcrumb-menu' : undefined}
									aria-expanded={open ? 'true' : undefined}
									aria-haspopup='true'
									onClick={handleClick}
									className={styles.menuButton}
								>
									<MoreHorizIcon fontSize='small' />
								</IconButton>
							)}
						</div>
					)
				})}
			</Breadcrumbs>
		</>
	)
}
