/* eslint-disable max-len */

import { UIMatch, generatePath } from 'react-router-dom'

import { Link } from '@mui/material'
import { useOrgProviderRoutes } from 'routes/employer-provider-routes'
import { DynamicBreadcrumb } from '../breadcrumbs'
import { routePaths } from '../route-paths'
import { CsRoute } from '../types'
import { prepareRoutes } from '../utils/prepareRoutes'
import { useOrgBenefitPlanRoutes } from './benefit-plans/useBenefitPlanRoutes'
import { useOrgOpenEnrollmentRoutes } from './open-enrollments/useOpenEnrollmentRoutes'

export const useOrgRoutes = (): CsRoute[] => {
	// useFlags()

	return prepareRoutes(
		[
			{
				path: routePaths.organizations.root,
				handle: {
					crumb: () => <Link href={routePaths.organizations.root}>Organizations</Link>,
				},
				children: prepareRoutes(
					[
						{
							// this is the index of admin's entire website
							index: true,
							lazy: () => import('pages/organizations').then((module) => ({ Component: module.default })),
						},
						{
							lazy: () => import('pages/create-org').then((module) => ({ Component: module.default })),
							path: routePaths.organizations.create.path,
						},
						{
							lazy: () => import('pages/edit-org').then((module) => ({ Component: module.EditOrgLayout })),
							path: `${routePaths.organizations.edit.root}/*`,
							handle: {
								crumb: (data: UIMatch<unknown, unknown>) => {
									return <DynamicBreadcrumb portalId={data.params.id ?? ''} />
								},
							},
							children: prepareRoutes(
								[
									{
										path: routePaths.organizations.edit.faq.path,
										lazy: () =>
											import('pages/faqs').then((module) => ({
												Component: module.default,
											})),
									},
									{
										path: routePaths.organizations.edit.flags.path,
										lazy: () =>
											import('pages/organization-flags').then((module) => ({
												Component: module.default,
											})),
									},
									useOrgBenefitPlanRoutes(),
									useOrgOpenEnrollmentRoutes(),
									{
										path: routePaths.organizations.edit.providers.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.organizations.edit.providers.path, {
														id: data.params.id ?? '',
													})}
												>
													Providers
												</Link>
											),
										},
										children: [
											{
												index: true,
												lazy: () =>
													import('pages/organization-providers').then((module) => ({ Component: module.default })),
											},
											{
												path: ':providerId/*',
												lazy: () => import('pages/employer-provider').then((module) => ({ Component: module.default })),
												children: useOrgProviderRoutes(),
												handle: {
													crumb: (data: UIMatch<any, any>) => {
														return <DynamicBreadcrumb portalId={data.params.providerId ?? ''} />
													},
												},
											},
										],
									},
									{
										path: routePaths.organizations.edit.financials.path,
										lazy: () =>
											import('pages/reconciliation-configuration').then((module) => ({
												Component: module.ReconciliationConfiguration,
											})),
									},
									{
										path: routePaths.organizations.edit.deductionGroups.path,
										lazy: () =>
											import('pages/organizations/pay-groups/organization-pay-groups').then((module) => ({
												Component: module.default,
											})),
									},
									{
										lazy: () =>
											import('pages/organization-schedules/organization-schedule-entries').then((module) => ({
												Component: module.default,
											})),
										path: routePaths.organizations.edit.schedules.entries.listing.path,
									},
									{
										path: routePaths.organizations.edit.qleEvents.path,
										lazy: () =>
											import('pages/edit-org/qle-events').then((module) => ({
												Component: module.default,
											})),
									},
								],
								{
									stripLeading: `${routePaths.organizations.edit.root}/`,
								},
							),
						},
					],
					{
						stripLeading: `${routePaths.organizations.root}/`,
					},
				),
			},
		],
		{ stripLeading: `${routePaths.organizations.root}/` },
	)
}
