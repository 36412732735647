export const queryKeys = {
	all: () => ['benefit-elections'] as const,

	benefitPlans: {
		all: () => [...queryKeys.all(), 'benefit-plans'] as const,

		getBenefitPlansForOrgQuery: (organizationId: string) =>
			[...queryKeys.benefitPlans.all(), 'organization', organizationId] as const,

		getById: (benefitPlanId: string) => [...queryKeys.all(), 'benefit-plan-by-id', benefitPlanId] as const,

		getDropdownOptions: (organizationId: string, providerId: string) =>
			[
				...queryKeys.benefitPlans.all(),
				'dropdown-options',
				'organization',
				organizationId,
				'provider',
				providerId,
			] as const,

		rates: {
			all: () => [...queryKeys.benefitPlans.all(), 'rates'] as const,
			getByRateInfoId: (rateInfoId: string) =>
				[...queryKeys.benefitPlans.all(), 'rates-info-get-latest', rateInfoId] as const,
		},
	},

	benefitProducts: {
		all: () => [...queryKeys.all(), 'benefit-products'] as const,
		getById: (benefitProductId: string) =>
			[...queryKeys.benefitProducts.all(), 'benefit-product-by-id', benefitProductId] as const,
		getRatingTypes: () => [...queryKeys.benefitProducts.all(), 'rating-types'] as const,
		getBenefitProductPlans: (benefitProductId: string) =>
			[...queryKeys.benefitProducts.all(), 'benefit-product-plans', benefitProductId] as const,
	},

	eligibilityRules: {
		all: () => [...queryKeys.all(), 'eligibility-rules'] as const,

		getAll: () => [...queryKeys.eligibilityRules.all()] as const,
	},

	productTemplates: {
		all: () => [...queryKeys.all(), 'product-templates'] as const,

		getById: (productTemplateId: string) =>
			[...queryKeys.productTemplates.all(), 'product-template-by-id', productTemplateId] as const,

		modular: {
			all: () => [...queryKeys.productTemplates.all(), 'modular'] as const,

			getById: (productTemplateId: string) =>
				[...queryKeys.productTemplates.modular.all(), 'product-template-by-id', productTemplateId] as const,
			getAvailableTemplateLayouts: () =>
				[...queryKeys.productTemplates.modular.all(), 'available-template-layouts'] as const,
			getTemplateLayoutById: (templateLayoutId: string) =>
				[...queryKeys.productTemplates.modular.all(), 'template-layout-by-id', templateLayoutId] as const,
		},
	},

	providers: {
		all: () => [...queryKeys.all(), 'providers'] as const,

		getById: (providerId: string) => [...queryKeys.providers.all(), 'provider-by-id', providerId] as const,

		getDropdownOptions: () => [...queryKeys.providers.all(), 'dropdown-options'] as const,

		getProvidersForOrg: (organizationId: string) =>
			[...queryKeys.providers.all(), 'organization', organizationId] as const,
	},

	rates: {
		all: () => [...queryKeys.all(), 'rates'] as const,

		ratesStrategies: {
			all: () => [...queryKeys.rates.all(), 'ratesStrategies'] as const,

			getAll: () => [...queryKeys.rates.ratesStrategies.all()] as const,
		},
	},

	support: {
		all: () => [...queryKeys.all(), 'support'] as const,

		getConstituentProductAvailability: (constituentId: string) =>
			[...queryKeys.all(), 'support', constituentId] as const,
		getCorestreamPolicyHistory: (workerId: string, benefitPlanId: string) =>
			[...queryKeys.all(), 'corestream-policy-history', workerId, benefitPlanId] as const,
		getAvailableTerminationDates: (electionId: string) =>
			[...queryKeys.all(), 'available-termination-dates', electionId] as const,
	},
	corestreamProducts: {
		all: () => [...queryKeys.all(), 'corestream-products'] as const,

		getAllCorestreamProducts: () => [...queryKeys.corestreamProducts.all()] as const,
		getCorestreamProductById: (corestreamProductId: string) =>
			[...queryKeys.corestreamProducts.all(), 'corestream-product-by-id', corestreamProductId] as const,
	},
	tokens: {
		all: () => [...queryKeys.all(), 'tokens'] as const,
	},
}

export const mutationKeys = {
	benefitPlans: {
		all: () => ['benefitelections'] as const,

		rates: {
			all: () => [...mutationKeys.benefitPlans.all(), 'rates'] as const,
			edit: () => [...mutationKeys.benefitPlans.rates.all(), 'edit'] as const,
		},
	},

	corestreamProducts: {
		all: () => ['corestream-products'] as const,

		editCorestreamProduct: () => [...mutationKeys.corestreamProducts.all(), 'edit'] as const,
	},
	productTemplates: {
		modular: {
			all: () => ['product-templates', 'modular'] as const,
			create: () => [...mutationKeys.productTemplates.modular.all(), 'create'] as const,
			edit: () => [...mutationKeys.productTemplates.modular.all(), 'edit'] as const,
		},
	},
	productTypeTokens: {
		create: () => ['product-type-tokens', 'create'] as const,
		edit: () => ['product-type-tokens', 'edit'] as const,
	},
}
