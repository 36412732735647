import { SiteEnvironment } from 'app/types'
import { createContext, useContext } from 'react'
import { Environments } from 'utils/env'

export const AppContext = createContext<{ site: SiteEnvironment }>({ site: { env: Environments.PROD } })

export const AppContextProvider = ({
	children,
	values,
}: {
	children: React.ReactNode
	values: { site: SiteEnvironment }
}) => {
	return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

export function useAppContext() {
	const values = useContext(AppContext)

	if (!values) {
		throw new Error('useAppContext must be used within an AppContextProvider')
	}

	return values
}
