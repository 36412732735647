import { AsyncProviderConfig, asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { StrictMode } from 'react'
import UrlBuilder from 'services/util/url-builder'
import { Environments } from 'utils/env'
import App from './app'
import { AppContextProvider } from './app-context'
import { SiteEnvironment } from './types'
import { getLdClientIdForEnv } from './utils/getLdClientIdForEnv'

export async function setupApp(site: SiteEnvironment, root) {
	new UrlBuilder().setHost(site.env)

	const LDProvider = await asyncWithLDProvider(await configureLaunchDarkly(site.env as Environments))

	root.render(
		<StrictMode>
			<LDProvider>
				<AppContextProvider
					values={{
						site,
					}}
				>
					<App />
				</AppContextProvider>
			</LDProvider>
		</StrictMode>,
	)
}

/**
 * Setup the LaunchDarkly contexts for the user and organization
 */
async function configureLaunchDarkly(env: Environments): Promise<AsyncProviderConfig> {
	let version = 'unknown'
	try {
		const response = await fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
		const { version: metaVersion } = await response.json()
		version = metaVersion
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to fetch version from meta.json:', error)
	}
	const ldClientId = getLdClientIdForEnv(env)

	return {
		clientSideID: ldClientId,
		context: {
			kind: 'multi',
			organization: {
				key: 'ADMIN',
				name: 'ADMIN',
			},
			user: {
				key: 'ADMIN',
				organization: 'ADMIN',
			},
		},
		options: {
			application: {
				id: 'cs-admin-ui',
				version: version,
			},
			eventCapacity: 500,
		},
		reactOptions: { useCamelCaseFlagKeys: false },
	}
}
