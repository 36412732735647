import { Menu, MenuItem } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { IconButton } from 'components/buttons'
import React from 'react'
import { useAuth } from 'react-oidc-context'

const UserMenu = (): JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => setAnchorEl(event.currentTarget)
	const handleClose = (): void => setAnchorEl(null)
	const { user, removeUser } = useAuth()

	const handleLogout = (e): void => {
		e.preventDefault()

		removeUser()
	}

	return (
		<>
			<IconButton
				ariaLabel='Open account information'
				aria-controls='simple-menu'
				aria-haspopup='true'
				color='secondary'
				onClick={handleClick}
			>
				<AccountCircleIcon />
			</IconButton>
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'center', vertical: 'top' }}
				style={{ zIndex: 10000 }}
				keepMounted
			>
				<MenuItem onClick={handleClose}>{user?.profile.name ?? 'User'}</MenuItem>
				<MenuItem onClick={handleLogout}>Logout</MenuItem>
			</Menu>
		</>
	)
}

export default UserMenu
