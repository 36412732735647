import { ColorSystemOptions, alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'

declare module '@mui/material/styles/experimental_extendTheme' {
	interface TypeBackground {
		neutral: string
	}
	interface PaletteBackgroundChannel {
		lighter: string
		darker: string
	}
	interface PaletteColorChannel {
		lighter: string
		darker: string
	}
	interface PaletteCommonChannel {
		lighter: string
		darker: string
	}
	interface SimplePaletteColorOptions {
		lighter: string
		darker: string
	}
	interface PaletteColor {
		lighter: string
		darker: string
	}
	interface PaletteTextChannel {
		lighter: string
		darker: string
	}
}

// SETUP COLORS

export const grey = {
	0: '#FFFFFF',
	100: '#F9FAFB',
	200: '#F4F6F8',
	300: '#DFE3E8',
	400: '#C4CDD5',
	500: '#919EAB',
	600: '#637381',
	700: '#454F5B',
	800: '#212B36',
	900: '#161C24',
}

export const primary = {
	lighter: '#FEE9D1',
	light: '#FDAB76',
	main: '#FA541C',
	dark: '#B3200E',
	darker: '#770508',
	contrastText: '#FFFFFF',
}

export const secondary = {
	lighter: '#E6DBFE',
	light: '#B195FE',
	main: '#754FFE',
	dark: '#4027B6',
	darker: '#1C0F79',
	contrastText: '#FFFFFF',
}

export const info = {
	lighter: '#CAFDF5',
	light: '#61F3F3',
	main: '#00B8D9',
	dark: '#006C9C',
	darker: '#003768',
	contrastText: '#FFFFFF',
}

export const success = {
	lighter: '#D8FBDE',
	light: '#86E8AB',
	main: '#128758',
	dark: '#1B806A',
	darker: '#0A5554',
	contrastText: '#FFFFFF',
}

export const warning = {
	lighter: '#FFF5CC',
	light: '#FFD666',
	main: '#FFAB00',
	dark: '#B76E00',
	darker: '#7A4100',
	contrastText: grey[800],
}

export const error = {
	lighter: '#FFE9D5',
	light: '#FFAC82',
	main: '#b30000',
	dark: '#B71D18',
	darker: '#7A0916',
	contrastText: '#FFFFFF',
}

export const common = {
	black: '#000000',
	white: '#FFFFFF',
}

export const action = {
	hover: alpha(grey[500], 0.08),
	selected: alpha(grey[500], 0.16),
	disabled: alpha(grey[500], 0.8),
	disabledBackground: alpha(grey[500], 0.24),
	focus: alpha(grey[500], 0.24),
	hoverOpacity: 0.08,
	disabledOpacity: 0.48,
}

const base = {
	primary,
	secondary,
	info,
	success,
	warning,
	error,
	grey,
	common,
	divider: alpha(grey[500], 0.2),
	action,
}

// ----------------------------------------------------------------------

export function palette(mode: 'light' | 'dark'): Partial<ColorSystemOptions['palette']> {
	const light = {
		...base,
		text: {
			primary: grey[800],
			secondary: grey[600],
			disabled: grey[500],
		},
		background: {
			paper: '#FFFFFF',
			default: '#FFFFFF',
			neutral: grey[200],
		},
		action: {
			...base.action,
			active: grey[600],
		},
	}

	const dark = {
		...base,
		text: {
			primary: '#FFFFFF',
			secondary: grey[500],
			disabled: grey[600],
		},
		background: {
			paper: grey[800],
			default: grey[900],
			neutral: alpha(grey[500], 0.12),
		},
		action: {
			...base.action,
			active: grey[500],
		},
	}

	return mode === 'light' ? light : dark
}
