import { Container, CssBaseline } from '@material-ui/core'
import { AppBar, Unstable_Grid2 as Grid, Toolbar, Tooltip } from '@mui/material'
import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { Menu } from '@mui/icons-material'
import { IconButton } from 'components/buttons'
import SideNav from 'components/side-nav'
import UserMenu from 'components/user-menu'
import { AppBreadcrumbs } from 'routes/breadcrumbs'
import logoUrl from '../assets/corestreamlogo.png?url'

import { useAppContext } from 'app/app-context'
import { Environments } from 'utils/env'
import styles from './layout.module.css'

const Layout = () => {
	const [open, setOpen] = useState(false)
	const handleDrawerToggle = (): void => setOpen((prevState): boolean => !prevState)
	const navigate = useNavigate()

	const appHeaderColor = useAppHeaderColorByEnv()

	return (
		<>
			<AppBar
				position='sticky'
				className={styles.appBar}
				sx={(theme) => ({
					backgroundColor: appHeaderColor + ' !important',
					zIndex: theme.zIndex.drawer + 1,
				})}
			>
				<Toolbar className={styles.toolbar} sx={{ display: 'grid' }}>
					<IconButton ariaLabel='open drawer' edge='start' onClick={handleDrawerToggle} className={styles.menuButton}>
						<Menu />
					</IconButton>
					<div className={styles.logoContainer}>
						<Tooltip title='Return Home'>
							<img alt='logo' className={styles.logo} onClick={(): void => navigate('/')} src={logoUrl} />
						</Tooltip>
					</div>
					<div className={styles.breadcrumbsContainer}>
						<AppBreadcrumbs />
					</div>
					<UserMenu />
				</Toolbar>
			</AppBar>
			<div className={styles.mainContent}>
				<CssBaseline />
				<SideNav open={open} handleDrawerToggle={handleDrawerToggle} />
				<Container maxWidth={false} className={styles.contentContainer}>
					<Grid
						spacing={2}
						justifyContent='flex-start'
						alignItems='flex-start'
						container
						className={styles.contentGrid}
					>
						<Outlet />
					</Grid>
				</Container>
			</div>
		</>
	)
}

function useAppHeaderColorByEnv(): string {
	const { site } = useAppContext()

	switch (site.env) {
		case Environments.PROD:
			return 'var(--mui-palette-primary-main)'
		case Environments.UAT:
			return 'var(--mui-palette-success-main)'
		case Environments.INT:
		case Environments.LOCAL:
		case Environments.DEV:
			return 'var(--int-background)'
		case 'preview':
			return '#4027B6'

		default:
			return 'var(--mui-palette-warning-main)'
	}
}

export default Layout
