import { API_NAME } from 'api/auth/constants'
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { authConstants } from 'constants/auth-constants'
import { User } from 'oidc-client-ts'
import UrlBuilder from 'services/util/url-builder'

const authUrl: string = new UrlBuilder(API_NAME).url()

const addToken = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
	const authority = authUrl
	const clientId = authConstants.clientId

	const oidcStorage = localStorage.getItem(`oidc.user:${authority}:${clientId}`)

	if (!oidcStorage) {
		return config
	}

	const user = User.fromStorageString(oidcStorage)

	const sessionToken = user.access_token

	if (sessionToken) {
		config.headers.Authorization = `Bearer ${sessionToken}`
	}

	return config
}

const timeoutErrorMessage = 'There was an issue completing your request.'

const successHandler = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => response.data

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any): AxiosResponse | Promise<AxiosResponse> => {
	if (error?.response?.status === 401 && window.location.pathname !== '/login') {
		window.location.href = window.location.protocol + '//' + window.location.host + '/login'
	}

	return Promise.reject({ ...error })
}

export const axiosInstance = axios.create({
	responseType: 'json',
	timeout: 20000,
	timeoutErrorMessage,
	withCredentials: true,
})

axiosInstance.interceptors.response.use(
	(res) => successHandler(res),
	(err) => errorHandler(err),
)
axiosInstance.interceptors.request.use(
	(config) => addToken(config),
	(error) => errorHandler(error),
)

export const axiosIncludeHeaderInstance = axios.create({
	responseType: 'json',
	timeout: 100000,
	timeoutErrorMessage,
	withCredentials: true,
})

axiosIncludeHeaderInstance.interceptors.response.use(
	(res) => res,
	(err) => errorHandler(err),
)

axiosIncludeHeaderInstance.interceptors.request.use(
	(config) => addToken(config),
	(error) => errorHandler(error),
)
