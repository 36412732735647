import { parseEnvAndDomainFromHost } from 'app/utils/parseEnvAndDomainFromHost'
import { Environments } from 'utils/env'

/**
 * Function to remove all attributes from HTML except href and target
 */
export const removeAttributes = (html: string) => {
	return html
		.replace(/<(\w+)([^>]*)>/g, (match, tagName, attributes) => {
			// Keep only href, src, and target attributes
			const filteredAttributes = attributes.replace(/\s+(?!(href|target|src)\s*=)[\w\.\:-]+\s*=\s*["'][^"']*["']/g, '')

			return `<${tagName}${filteredAttributes}>`
		})
		.replaceAll(/<span(.*?)>/g, '')
		.replaceAll('</span>', '')
		.replaceAll(/\s*&nbsp;/g, '')
		.replaceAll('"', `'`)
}

/**
 * Converts pasted or typed out items into a clean unordered list
 * @param inputHtml html before conversion
 * @returns Unordered List
 */
export function convertToUnorderedList(inputHtml) {
	// Remove unneeded closing tags and HTML entitiy
	const removedHtml = inputHtml.replaceAll('</ul>', '').replaceAll('</div>', '').replaceAll('&nbsp;', '')

	// Replace seperators with a standard seperator (in this case '</li>')
	const replacedHtml = removedHtml
		.replaceAll('<br>', '</li>')
		.replaceAll('</p>', '</li>')
		.replace(/(<\/li>)+/g, '</li>')

	// Remove any opening tags
	const cleanedHtml = replacedHtml.replace(/<(?!a\b)[a-zA-Z]+(>|.*?[^?]>)/g, '')

	// Split our string by our only seperator left, '/li>'
	const listItems = cleanedHtml.split('</li>')

	// Step 3: Construct the <ul> list
	let outputHtml = '<ul>'
	listItems.forEach((item) => {
		const cleanedItem = item.trim()
		if (cleanedItem) {
			outputHtml += `<li>${cleanedItem}</li>`
		}
	})
	outputHtml += '</ul>'

	return outputHtml
}

/**
 * Activating listeners to ensure formatting isn't done when certain toolbar buttons are clicked
 * This is needed to ensure that users can utilize the toolbar without having the field be formatted prematurely
 */
export const handleEditorReady = (editor, setIsToolbarClicked) => {
	const editorInstance = editor

	// Add event listeners to detect toolbar interactions
	const toolbarElement = editorInstance.ui.view.toolbar.element

	const handleMouseDown = () => setIsToolbarClicked(true)
	const handleMouseUp = () => setTimeout(() => setIsToolbarClicked(false), 0)

	toolbarElement.addEventListener('mousedown', handleMouseDown)
	toolbarElement.addEventListener('mouseup', handleMouseUp)

	// Add event listener for clicks within the internal text field and whenever the link actions toolbar is displayed
	editorInstance.editing.view.document.on('click', () => {
		const linkToolbarElement = document.querySelector('.ck-link-actions, .ck-balloon-rotator')
		if (linkToolbarElement) {
			linkToolbarElement.addEventListener('mousedown', handleMouseDown)
			linkToolbarElement.addEventListener('mouseup', handleMouseUp)
		}
	})
}

export function getCkEditorLicenseKey(): string {
	const site = parseEnvAndDomainFromHost(window.location.host)
	const env = site.env as Environments

	if (env === Environments.PROD) return import.meta.env.VITE_CKEDITOR_API_KEY

	return import.meta.env.VITE_CKEDITOR_API_KEY_DEV
}
