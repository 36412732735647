import { BenefitPlanMarketingContentFormType } from 'components/benefit-plan-marketing-content-form/types/benefit-plan-marketing-content-form-type'
import { RateFormTypeForPost } from 'components/rates-form/types'
import { SelectItems } from './form-select'

export const convertNullString = (str: string): string => (str === 'null' || !str ? '' : str)
export const splitOnCapitalLetter = (str: string): string => str.split(/(?=[A-Z])/).join(' ')
export const removeHash = (str?: string): string | undefined => (str && str.includes('#') ? str.split('#')[1] : str)
export const maxCharMessage = (name: string, maxChar: number): string =>
	`${name} must not be more than ${maxChar} characters`

const defaultEmailHost = '@corestream.com'
/**
 * @param value - current phone input value
 * @returns {string} formatted phone number
 */
const areaCodeAndNextThree = (value: string): string => `(${value.slice(0, 3)}) ${value.slice(3)}`
/**
 * @param {string} value - current phone input value
 * @returns {string} '(xxx) xxx-', (xxx) xxx-x', '(xxx) xxx-xx', '(xxx) xxx-xxx', '(xxx) xxx-xxxx'
 */

const finalPhoneNumber = (value: string): string => `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`

export const timeToRedirect = 2500 // time in ms to redirect to the next screen

/**
 *
 * @param email - email address without the host
 * @returns {string} formatted email address with the host
 */
export const formatEmail = (email: string): string => `${email}${defaultEmailHost}`
/**
 * Updates phone number to be formatted: (xxx) xxx-xxxx
 * @param value - string value of the phone number
 * @returns {string} formatted phone number
 */
export const formatPhoneNumber = (value: string): string => {
	// only allows 0-9 inputs
	const currentValue = value.replace(/[^\d]/g, '')

	if (currentValue.length < 4) {
		return currentValue
	}

	if (currentValue.length < 7) {
		return areaCodeAndNextThree(currentValue)
	}

	return finalPhoneNumber(currentValue)
}

export const createFormData = (data): FormData => {
	const form = new FormData()
	for (const key in data) {
		if (data[key] === null || data[key] === undefined) continue
		if (typeof data[key] === 'object' && data[key] && data[key].length) {
			data[key].forEach((item) => {
				form.append(`${key}[]`, item)
			})
		} else {
			form.append(key, data[key])
		}
	}

	return form
}

//Specific to creating marketing content form data. If this is used again elsewhere, give it a generic name
export const CreateMarketingContentFormData = (data: BenefitPlanMarketingContentFormType) => {
	const form = new FormData()
	for (const key in data) {
		if (typeof data[key] === 'object' && data[key] && data[key].length) {
			data[key].forEach((item: any) => {
				form.append(item.key, item.enabled ? item.value : '')
				form.append(`${item.key}_Enabled`, item.enabled)
			})
		} else {
			form.append(key, data[key])
		}
	}

	return form
}

export const createRatesFormData = (data: RateFormTypeForPost) => {
	const form = new FormData()

	for (const key in data) {
		if (typeof data[key] === 'object' && data[key] && data[key].length) {
			form.append('serializedRates', JSON.stringify(data[key]))
		} else {
			form.append(key, data[key])
		}
	}

	return form
}

export function jsonToFormData(json, parentKey = '', formData = new FormData()) {
	// Check if the input is an object
	if (typeof json === 'object' && json !== null) {
		// Iterate through the keys of the object
		for (const key in json) {
			// Skip inherited properties
			if (json.hasOwnProperty(key)) {
				// Construct the key for FormData
				const formKey = parentKey ? `${parentKey}[${key}]` : key

				// If the value is an object and not a File or Blob, recursively call the function
				if (
					typeof json[key] === 'object' &&
					json[key] !== null &&
					!(json[key] instanceof File) &&
					!(json[key] instanceof Blob)
				) {
					jsonToFormData(json[key], formKey, formData)
				} else {
					// Append the key-value pair to FormData
					formData.append(formKey, json[key])
				}
			}
		}
	}

	// Return the FormData object
	return formData
}

export const formHeaders = {
	headers: {
		'content-type': 'multipart/form-data',
	},
}

export const mapEnumToSelectItems = (obj: object) => {
	const selectItems = Object.keys(obj).map((key) => {
		return {
			id: key,
			value: obj[key],
			text: obj[key],
		} as SelectItems
	})

	return selectItems
}
export interface FileType {
	name: string
	lastModified: number
	lastModifiedDate: Date
	webkitRelativePath: string
	size: number
	type: string
	path: string
	preview: string
}
