import { FileRepository, Plugin, logWarning } from 'ckeditor5'
import { CkeditorSimpleUploadConfig } from 'components/ckeditor/ckeditor-configurations/types'

export class SimpleFileUploadAdapter extends Plugin {
	static get requires(): [typeof FileRepository] {
		return [FileRepository]
	}

	static get pluginName() {
		return 'SimpleFileUploadAdapter'
	}

	init(): void {
		const options = this.editor.config.get('simpleUpload') as CkeditorSimpleUploadConfig | undefined

		if (!options) {
			return
		}

		if (!options.uploadUrl) {
			// eslint-disable-next-line no-console
			console.warn(
				logWarning(
					'simple-upload-adapter-missing-uploadUrl: Missing the "uploadUrl" property in the ' +
						'"simpleUpload" editor configuration.',
				),
			)

			return
		}

		this.editor.plugins.get(FileRepository).createUploadAdapter = (loader): FileUploadAdapter => {
			return new FileUploadAdapter(loader, options)
		}
	}
}

class FileUploadAdapter {
	private controller: AbortController | null = null

	constructor(
		private loader: any,
		private uploadConfig: CkeditorSimpleUploadConfig,
	) {}

	async upload(): Promise<{ default: string }> {
		try {
			this.controller = new AbortController()
			const file = await this.loader.file
			const formData = new FormData()
			formData.append('upload', file)

			const response = await fetch(this.uploadConfig.uploadUrl, {
				method: 'POST',
				headers: this.uploadConfig.headers,
				body: formData,
				credentials: this.uploadConfig.withCredentials ? 'include' : undefined,
				signal: this.controller.signal,
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			const data = await response.json()

			// The server should return an object with a 'url' property
			// containing the URL of the uploaded image
			if (!data.url) {
				throw new Error('Upload response does not contain image URL')
			}

			// Return the URL in the format CKEditor expects
			return {
				default: data.url,
			}
		} catch (error) {
			console.error('Upload failed:', error)
			throw error
		}
	}

	abort(): void {
		if (this.controller) {
			this.controller.abort()
			this.controller = null
		}
	}
}
