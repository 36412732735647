import { listItemButtonClasses } from '@mui/material'
import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function list(): CssVarsThemeOptions['components'] {
	return {
		MuiList: {
			styleOverrides: {
				root: () => ({
					padding: '8px',
					gap: '4px',
					display: 'flex',
					flexDirection: 'column',
				}),
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: ({ theme }) => ({
					borderRadius: '10px',
					padding: '8px',
					[`&.${listItemButtonClasses?.selected}`]: {
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
						'&:hover': {
							backgroundColor: theme.palette.primary.dark,
						},
					},
				}),
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: ({ theme }) => ({
					color: 'inherit',
					minWidth: 'auto',
					marginRight: theme.spacing(2),
				}),
			},
		},
		MuiListItemAvatar: {
			styleOverrides: {
				root: ({ theme }) => ({
					minWidth: 'auto',
					marginRight: theme.spacing(2),
				}),
			},
		},
		MuiListItemText: {
			styleOverrides: {
				root: {
					margin: 0,
				},
				multiline: {
					margin: 0,
				},
			},
		},
	}
}
